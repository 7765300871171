.about {
	margin-top: 2em;
	display: flex;
	justify-content: center;
	&-description{
		text-align: justify;
	}
}

@media (min-width: 600px) {
	.about-card {
		min-width: 600px;
	}
}