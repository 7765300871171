// Cards Carousel
// ----------------------------------------------

.icon-cards {
  position: relative;
	width: 60vw;
	height: 40vw;
  max-width: 380px;
  max-height: 250px;
  margin: 0;
  color: white;
	perspective: 1000px;
  transform-origin: center;

	// This is the element that rotates with the animation

	&__content {
		position: absolute;
		width: 100%;
		height: 100%;
    transform-origin: center;
		transform-style: preserve-3d;
		transform: translateZ(-30vw) rotateY(0);
		animation: carousel 15s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards;
    
    &.step-animation {
      animation: carousel 8s infinite steps(1) forwards;
    }
	}

	// Individual cards

	&__item {
		position: absolute;
		top: 0;
		left: 0;
    right: 0;
    bottom: 0;
		width: 60vw;
		height: 40vw;
    max-width: 380px;
    max-height: 250px;
    box-shadow: 0 5px 20px rgba(0,0,0,.1);
		border-radius: 6px;
    transform-origin: center;

		&:nth-child(1) {
			background: url('../../images/tulip.jpg') center/cover;
			transform: rotateY(0) translateZ(35vw);
		}

		&:nth-child(2) {
			background: url('../../images/bee.jpg') center/cover;
			transform: rotateY(120deg) translateZ(35vw);
		}

		&:nth-child(3) {
			background: url('../../images/canopy.jpg') center/cover;
			transform: rotateY(240deg) translateZ(35vw);
		}
	}
}

// Carousel animation

@keyframes carousel {
	0%,  17.5%  { transform: translateZ(-35vw) rotateY(0); }
	27.5%, 45%  { transform: translateZ(-35vw) rotateY(-120deg); }
	55%, 72.5%  { transform: translateZ(-35vw) rotateY(-240deg); }
	82.5%, 100% { transform: translateZ(-35vw) rotateY(-360deg); }
}

.animationrotate {
	margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


