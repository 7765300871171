@use 'reset';
@use 'vars';

body {
  font-family: vars.$font1;

}

.App {
  background: url('../images/wallpaper.jpg') no-repeat center/cover;
  background-attachment: fixed;
  min-height: 100vh;
  background-position-y: bottom;
  padding: 1em;
}