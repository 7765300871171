.prestations{
	display: flex;
	flex-wrap: wrap;
	margin-top: 2em;
	justify-content: center;
	width: 100%;
	z-index: 1;

	&-card{
		display: flex;
		flex-wrap: wrap;
		margin: 1em;
		justify-content: space-between;
		margin-block-end: auto;
		
	}
	&-card__title{
		display: flex;
		padding-right: 1em;
	}
	&-card__price{
		display: flex;
		font-size: 1em;
		margin-bottom: 1em;
	}
	&-link{
		display: flex;
		justify-content: center;
		justify-items: center;
	}
}
.modalModify{
	position: fixed;
	z-index: 2;
	top: 15px;
	animation-duration: 0.25s;
	animation-name: growBox;
	width: 800px;
	height: max-content;
	background-color: white;
	border: 1rem solid #9C27B0;
}

@keyframes growBox{
	from {
		width: 300px;
		height: 200px;
	} 
	to{
		border: 1rem solid #9C27B0;
		width: 800px;
		height: max-content;
	}
}
.form-data{
	&__inputPrestation{
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	
	&__inputTextfield{
		margin: 0.5em;
		gap: 1em;
		display: flex;
		flex-direction: column;
	}
}
@media screen and (max-width: 850px){
	.modalModify{
		width: 370px;
	}
}


