.prisederendezvous {
	margin-top: 2em;
	display: flex;
	justify-content: center;

	&-description {}

	&-link {
		display: flex;
		justify-content: center;
	}
}

@media (min-width: 600px) {
	.prisederendezvous-card {
		min-width: 600px;
	}
}