.errorpage {
	margin-top: 2em;
	display: flex;
	justify-content: center;

	&-card{
		width: 70%;
		height: 70%;
	}
}

@media (min-width: 600px) {
	.errorpage {
		min-width: 600px;
	}
}