.contact {
	margin-top: 2em;
	display: flex;
	justify-content: center;

	&-title {
		display: flex;
		justify-content: center;
	}

	&-card {
		width: 345px;
		max-width: 345 px;
	}

	&-box {
		min-width: 300px;
	}
	&-img{
		max-width: 100%;
		max-height: 100%;
		object-fit: cover;
	}

}

@media (min-width: 600px) {
	.contact-card {
		min-width: 600px;
	}
}