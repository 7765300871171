.legalnotice {
	margin-top: 2em;
	display: flex;
	justify-content: center;


}

@media (min-width: 600px) {
	.legalnotice-card {
		min-width: 600px;
	}
}