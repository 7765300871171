.utilsLinks {
	margin-top: 2em;
	display: flex;
	justify-content: center;
&-description{
		text-align: left;
	}
&-card{
	

}
&-item{
	text-align: justify;
}

}

@media (min-width: 600px) {
	.utilsLinks-card {
		min-width: 600px;
	}
}